import React from 'react';
import PhotoCard from './PhotoCard';
import styles from '../styles/PhotoGrid.module.css';

const photos = [
  {
    id: 1,
    src: 'https://i.ibb.co/1mCyz0y/IMG-20190325-180455.jpg',
    thumb: `https://i.ibb.co/kSLbmGb/IMG-20190325-180455.jpg`,
    caption: 'City vibes',
  },
  {
    id: 2,
    src: 'https://i.ibb.co/ZhB4cpf/IMG-20190407-175453.jpg',
    thumb: `https://i.ibb.co/2jvVc1F/IMG-20190407-175453.jpg`,
    caption: 'City vibes',
  },
  {
    id: 3,
    src: 'https://i.ibb.co/h1HJDYC/IMG-20190414-162141.jpg',
    thumb: `https://i.ibb.co/XkprSYV/IMG-20190414-162141.jpg`,
    caption: 'Blue sky',
  },
  {
    id: 4,
    src: 'https://i.ibb.co/7Gy3PGx/IMG-20190508-205702.jpg',
    thumb: `https://i.ibb.co/hK8GbKx/IMG-20190508-205702.jpg`,
    caption: 'City vibes',
  },
  {
    id: 5,
    src: 'https://i.ibb.co/zVpG3Lj/IMG-20190521-181751.jpg',
    thumb: `https://i.ibb.co/TM6mQnp/IMG-20190521-181751.jpg`,
    caption: 'Colorful flowers',
  },
  {
    id: 6,
    src: 'https://i.ibb.co/TgSyXr9/IMG-20190827-204953.jpg',
    thumb: `https://i.ibb.co/YLMg9bH/IMG-20190827-204953.jpg`,
    caption: 'A beautiful sunset',
  },
  {
    id: 7,
    src: 'https://i.ibb.co/Rcb9QkD/IMG-20190827-205303-02-1.jpg',
    thumb: `https://i.ibb.co/6FwmD7W/IMG-20190827-205303-02-1.jpg`,
    caption: 'A beautiful sunset',
  },
  {
    id: 8,
    src: 'https://i.ibb.co/nMF58KX/IMG-20200925-173215-1.jpg',
    thumb: `https://i.ibb.co/KxJ3z8t/IMG-20200925-173215-1.jpg`,
    caption: 'Autumn leaves',
  },
  {
    id: 9,
    src: 'https://i.ibb.co/HFb1XQP/IMG-20191130-145050.jpg',
    thumb: `https://i.ibb.co/5MSt2Vj/IMG-20191130-145050.jpg`,
    caption: 'Blue sky',
  },
  {
    id: 10,
    src: 'https://i.ibb.co/cLLjKjM/IMG-20200910-142155.jpg',
    thumb: `https://i.ibb.co/WttLNLq/IMG-20200910-142155.jpg`,
    caption: 'Red berries',
  },
  {
    id: 11,
    src: 'https://i.ibb.co/jH6TQKb/IMG-20200918-193716.jpg',
    thumb: `https://i.ibb.co/XV2bKBj/IMG-20200918-193716.jpg`,
    caption: 'City vibes',
  },
  {
    id: 12,
    src: 'https://i.ibb.co/XC6Gj8w/IMG-20201106-153610.jpg',
    thumb: `https://i.ibb.co/DRmjzpq/IMG-20201106-153610.jpg`,
    caption: 'Plants',
  },
];

const PhotoGrid: React.FC = () => {
  return (
    <div className={styles.photoGrid}>
      {photos.map((photo) => (
        <PhotoCard
          key={photo.id}
          src={photo.src}
          caption={photo.caption}
        />
      ))}
    </div>
  );
};

export default PhotoGrid;
