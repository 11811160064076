// src/components/PhotoCard.tsx
import React, { useState } from 'react';
import styles from '../styles/PhotoCard.module.css';

interface PhotoCardProps {
  src: string;
  caption: string;
  thumb?: string;
}

const PhotoCard: React.FC<PhotoCardProps> = ({ src, caption, thumb }) => {
  return (
    <div>
      <div className={styles.photoCard}>
        <img src={src} alt={caption} className={styles.photo}/>
      </div>
    </div>
  );
};

export default PhotoCard;
